<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
    	<v-col cols="12" md="6" lg="4" v-for="(marca , i) in marcas" :key="i">
	      <v-card class="elevation-0 transparent">
	        <v-img :src="url + marca.foto" contain aspect-ratio="2"/>
	      </v-card>
    	</v-col>
    </v-row>
  </v-container>
</template>

<script>
  import $ from 'jquery'
  import {mapActions , mapGetters} from 'vuex'
	import metodos from '@/mixins/metodos.js';
  import Vue from 'vue'
	var accounting = require("accounting");
  Vue.filter('currency', (val, dec) => {
    return accounting.formatMoney(val, '$', dec)
  })

  import card_articulo from '@/components/card_articulo.vue'

  export default {
		mixins:[metodos],

    components: {
      card_articulo,
    },

    data (){
      return {
        marcas:[],
        url   : this.$http.options.root + 'fotos-fetish/' ,
      }
    },

    created(){
      this.init();
      console.log(this.seguimiento)
    },

    computed:{
      ...mapGetters('Listado',['Loading','listado_productos','seguimiento']),
      ...mapGetters('login'  ,['getusuarioFetish']),
    },

    methods:{

      init(){
      	this.marcas = []

				this.$http.get('marcas.list').then( response =>{
					this.marcas = response.body
					console.log( this.marcas)
				}).catch( error =>{
					this.validarError(error )
				})
      },
    }
  }
</script>

